import { createRouter, createWebHistory, LocationQuery, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import SearchResults from '@/views/SearchResults.vue'
import DevReporter from '@/views/DevReporter.vue'
import NotFound from '@/views/NotFound.vue'
import StaticContent from '@/views/StaticContent.vue'
import Contact from '@/views/Contact.vue'
import ResellYourTicket from '@/views/ResellYourTicket.vue'
import SeekaBrandSetupRequest from '@/views/SeekaBrandSetupRequest.vue'
import { HomepageQueryParams } from './types'
import { analyticsApi } from '@/analytics/analyticsApi'
import qs from 'qs';

import { ABOUT_OZTIX, CUSTOMER_SUPPORT, FAQS, PRIVACY, PRIVACY_POLICY, TERMS_AND_CONDITIONS, THANK_YOU_CUSTOMER_SUPPORT, CAREERS, DEADCOOL } from '@/content'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: route => ({ query: route.query } as HomepageQueryParams)
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults
  },
  {
    path: '/dev-reporter',
    name: 'DevReporter',
    component: DevReporter
  },
  {
    path: '/about',
    name: 'AboutOztix',
    component: StaticContent,
    props: { title: 'About Oztix', content: ABOUT_OZTIX }
  },
  {
    path: '/customer-support',
    name: 'CustomerSupport',
    component: Contact,
    props: { title: 'Customer Support', content: CUSTOMER_SUPPORT }
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: StaticContent,
    props: { title: 'Terms & Conditions of Sale', content: TERMS_AND_CONDITIONS }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: StaticContent,
    props: { title: 'Privacy Statement, Privacy Policy And Collection Notification Statement', content: PRIVACY }
  },
  {
    path: '/careers',
    name: 'Careers',
    component: StaticContent
    ,props: {title: 'A place for career info, jobs and the like', content: CAREERS, contentClass: 'content-career' }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: StaticContent,
    props: {
      title: 'Privacy Statement, Privacy Policy And Collection Notification Statement', content: PRIVACY_POLICY
    }
  },
  {
    path: '/frequently-asked-questions',
    name: 'FrequentlyAskedQuestions',
    component: StaticContent,
    props: {
      title: 'Frequently Asked Questions', content: FAQS,
    }
  },
  {
    path: '/resell-your-ticket',
    name: 'ResellYourTicket',
    component: ResellYourTicket
  },
  {
    path: '/seeka-brand-setup-request',
    name: 'SeekaBrandSetupRequest',
    component: SeekaBrandSetupRequest
  },
  {
    path: '/customer-support/thank-you', /* dont' forget to change SalesforceWeb2CaseRetUrl in config + Octopus */
    name: 'ThankYouCustomerSupport',
    component: StaticContent,
    props: {
      title: 'Thank you!', content: THANK_YOU_CUSTOMER_SUPPORT,
    }
  },
  {
    path: '/deadcool',
    name: 'DeadCool',
    component: StaticContent,
    props: { title: 'Dead Cool', content: DEADCOOL }
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: 'NotFound'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  parseQuery: (search) => {
    return qs.parse(search) as unknown as LocationQuery // Work-around for error "Type '(search: string) => ParsedQs' is not assignable to type '(search: string) => LocationQuery'.""
  },
  stringifyQuery(query) {
    const results = qs.stringify(query);
    return results ? results : '';
  },
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { left: 0, top: 0 }
  }
})

router.afterEach(async () => {
  const response = await analyticsApi.sendPageView();

  if (response == null) {
    console.warn("developer cookie is set, not sending events to IPA");
    return;
  }
})

export default router
