
import { defineComponent } from "vue";
import MenuButton from "@/components/Button/MenuButton.vue";
import CloseButton from "@/components/Button/CloseButton.vue";
import { Copyright } from "@/components";

export default defineComponent({
  name: "NavMenu",
  components: {
    CloseButton,
    MenuButton,
    Copyright,
  },
  data() {
    return {
      isOpen: false,
      menuButton: null as null | HTMLButtonElement,
      closeButton: null as null | HTMLButtonElement,
    };
  },
  mounted() {
    this.menuButton = this.$refs.menuButton as HTMLButtonElement;
    this.closeButton = this.$refs.closeButton as HTMLButtonElement;
  },
  watch: {
    $route(): void {
      /* on route change, if the menu is open, need to close it */
      this.closeMenu();
    },
  },
  methods: {
    openMenu() {
      if (!this.isOpen) {
        this.isOpen = true;
        const html = document.querySelector("html");
        if (html && html instanceof HTMLHtmlElement) {
          html.classList.add("no-scroll");
        }

        const body = document.querySelector("body");
        if (body && body instanceof HTMLBodyElement) {
          body.classList.add("no-scroll");
        }
      }
    },
    closeMenu() {
      if (this.isOpen) {
        this.isOpen = false;

        const html = document.querySelector("html");
        if (html && html instanceof HTMLHtmlElement) {
          html.classList.remove("no-scroll");
        }

        const body = document.querySelector("body");
        if (body && body instanceof HTMLBodyElement) {
          body.classList.remove("no-scroll");
        }
      }
    },
    ticketYourEventClick() {
      this.$posthog?.capture("website:ticket_your_event_link_click", {
        location: "navmenu",
        brand: "Oztix",
      });
    },
  },
});
